import * as protomaps from 'protomaps';
import { memo, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useAppSelector } from '../../../App/store';
import usePLU from '../../../shared/hooks/usePLU';
import { getMapState } from '../../map/mapSlice';
import { MyPaintSymbolizerPluOther } from './methods';
import { PolygonLabelSymbolizer } from './symbolizer/symbolizer';

// Component to manage the display of PLU (Local Urbanism Plan) layers on a map using PMTiles
function PmTilesPluOther() {
  const map = useMap();
  const { pluOthersPmtilesUrl, pluOthers } = usePLU();
  const { geolocDatas } = useAppSelector(getMapState);

  // State to store the metadata of the PMTiles
  const [metadata, setMetadata] = useState<any>(null);

  // Fetch metadata from the PMTiles URL when it's available
  useEffect(() => {
    if (pluOthersPmtilesUrl) {
      const p = new protomaps.PMTiles(pluOthersPmtilesUrl);
      p.getMetadata()
        .then((m: any) => {
          setMetadata(m); // Store the metadata in the state
        })
        .catch((err: any) => console.error('Metadata fetch error:', err));
    }
  }, [pluOthersPmtilesUrl]);

  // Helper function to retrieve an attribute value from an element
  const getAttributeValue = (elt: any, attributeName: string): any => {
    const attribute = elt.attributes.find(
      (attr: any) => attr.attribute === attributeName
    );
    return attribute?.values[0] ?? null;
  };

  // Function to remove all layers with the ID 'plu_others' from the map
  const resetAllLayers = () => {
    map.eachLayer((l: any) => {
      if (l.options.layer_group === 'plu_others') {
        map.removeLayer(l);
      }
    });
  };

  // Function to selectively reset/remove certain layers based on metadata and display rules
  const resetLayer = () => {
    if (pluOthers && metadata?.tilestats?.layers) {
      // Flatten the PLU hierarchy into a single array for easier handling
      const pluOthersFlat = pluOthers
        .map((elt) => elt.childs)
        .flat(1)
        .map((elt) => elt.childs)
        .flat(1);

      // Loop through the layers in the metadata and remove non-displayed layers
      metadata.tilestats.layers.map((elt: any) => {
        map.eachLayer((l: any) => {
          if (l.options.id && l.options.id.startsWith('plu_others')) {
            const layer = l as any;
            const attributeLibelle = getAttributeValue(elt, 'libelle');
            const layerId = `plu_others_${attributeLibelle}_${elt.layer}`;

            // If the layer should not be displayed, remove it from the map
            if (
              pluOthersFlat.find(
                (item) => item?.libelle === attributeLibelle && !item?.displayed
              ) &&
              l.options.id === layerId
            ) {
              map.removeLayer(layer);
            }
          }
        });
      });
    }
  };

  // Reset all PLU layers when the PLU URL or geographic location changes
  useEffect(() => {
    if (pluOthersPmtilesUrl && geolocDatas?.inseeCode) {
      resetAllLayers();
    }
  }, [pluOthersPmtilesUrl, geolocDatas?.inseeCode]);

  // Main effect to manage the adding and resetting of PLU layers on the map
  useEffect(() => {
    if (pluOthersPmtilesUrl && metadata && pluOthers) {
      // Reset layers before adding new ones
      resetLayer();

      // Flatten PLU data into a single array
      const pluOthersFlat = pluOthers
        .map((elt) => elt.childs)
        .flat(1)
        .map((elt) => elt.childs)
        .flat(1);

      // Loop through metadata layers to add layers to the map
      metadata.tilestats.layers.map((elt: any) => {
        const attributeLibelle = getAttributeValue(elt, 'libelle');

        // Only add layers that should be displayed
        if (
          pluOthersFlat.find(
            (item) => item?.displayed && item.libelle === attributeLibelle
          )
        ) {
          const layerId = `plu_others_${attributeLibelle}_${elt.layer}`;

          // Check if a layer with the same ID already exists
          let layerExists = false;
          map.eachLayer((l: any) => {
            if (l.options.id === layerId) {
              layerExists = true;
            }
          });

          // Add the layer if it doesn't exist yet
          if (!layerExists) {
            const layer = protomaps.leafletLayer({
              paint_rules: [
                {
                  dataLayer: elt.layer,
                  symbolizer: new MyPaintSymbolizerPluOther(), // Custom symbolizer for PLU display
                  minzoom: 0,
                  maxzoom: 22,
                },
              ],
              label_rules: [
                {
                  dataLayer: elt.layer,
                  symbolizer: new PolygonLabelSymbolizer({
                    label_props: () => ['libelle'],
                    fill: 'white',
                    width: 2,
                    stroke: 'red',
                    font: '600 14px sans-serif',
                    lineHeight: 1.2,
                  }),
                  minzoom: 10,
                  maxzoom: 22,
                },
              ],
              attribution: '',
              url: new protomaps.PMTiles(pluOthersPmtilesUrl).source.getKey(),
            });

            // Set layer options and add it to the map
            layer.options.layer_group = 'plu_others';
            layer.options.layer_pmtiles_group = 'plu_others_pmtiles';
            layer.options.id = layerId;
            layer.options.zIndex = 3;
            layer.options.opacity = 0.7;
            layer.addTo(map);
          }
        }
      });
    } else {
      resetLayer(); // Reset layers if data isn't available
    }
  }, [pluOthers, metadata, pluOthersPmtilesUrl]);

  // This component renders nothing, it only manages map layers
  return null;
}

export default memo(PmTilesPluOther);
