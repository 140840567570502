import React, { memo } from 'react';
import { Route, Routes } from 'react-router';
import DashboardPage from '../../pages/DashboardPage';
import ExternalConnection from '../../pages/ExternalConnection';
import HomePage from '../../pages/HomePage';
import MaintenancePage from '../../pages/MaintenancePage';
import MyAccountPage from '../../pages/MyAccountPage';
import PasswordResetPage from '../../pages/PasswordResetPage';
import PdfLandingPage from '../../pages/PdfLandingPage';
import StreetviewPage from '../../pages/StreetviewPage';
import View3dPage from '../../pages/View3dPage';
import { getAppState } from '../appSlice';
import { useAppSelector } from '../store';
import AuthenticatedRoute from './AuthenticatedRoute';
import OrpiRoute from './OrpiRoute';

function RouteContainer(): React.ReactElement {
  const { maintenancePageOnly } = useAppSelector(getAppState);

  return (
    <Routes>
      {maintenancePageOnly ? (
        <Route path="*" Component={MaintenancePage} />
      ) : (
        // Public routes
        <>
          <Route path="/external_connection" Component={ExternalConnection} />
          <Route path="/reset_password" Component={PasswordResetPage} />
          <Route path="/init_first_password" Component={PasswordResetPage} />

          {/* Authenticated routes */}
          <Route element={<AuthenticatedRoute />}>
            <Route path="/account" Component={MyAccountPage} />
            <Route path="/dashboard" Component={DashboardPage} />
            <Route
              path="/pdf-urbanease"
              element={<PdfLandingPage type="URBANEASE" />}
            />
            <Route path="/3d-view" element={<View3dPage />} />
            <Route path="/street-view" element={<StreetviewPage />} />
          </Route>

          <Route element={<OrpiRoute />}>
            <Route path="/pdf-orpi" element={<PdfLandingPage type="ORPI" />} />
            <Route
              path="/pdf-orpi-terrain"
              element={<PdfLandingPage type="ORPI_LAND" />}
            />
          </Route>

          <Route path="/" Component={HomePage} />

          {/* Default pages */}
          <Route path="*" Component={HomePage} />
        </>
      )}
    </Routes>
  );
}

export default memo(RouteContainer);
